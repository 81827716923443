@import 'styles/mixins';
@import 'styles/colors';

.searchBg {
  position: relative;
  z-index: 1;
  height: 268px;
  @media screen and (max-width: 1280px) {
    height: 164px;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center top;
    overflow: hidden;
    z-index: -1;
  }
}

.mainHeading {
  margin-top: 16px;
  margin-bottom: 37px;
  @media screen and (max-width: 1280px) {
    margin-top: 12px;
    margin-bottom: 24px;
  }
}

.searchContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.oldGlobalSearch {
  min-width: 300px;
  max-width: 300px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0 60px 0;
  @media screen and (max-width: 1280px) {
    padding: 24px 0;
  }
}
